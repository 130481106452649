<template>
  <div id="app">
    <template v-if="$route.path === '/login'">
      <router-view></router-view>
    </template>
    <el-container v-else>
      <el-header>
        <el-menu
          :default-active="$route.path"
          mode="horizontal"
          router
          background-color="#545c64"
          text-color="#fff"
          active-text-color="#ffd04b">
          <el-menu-item index="/home">首页</el-menu-item>
          <el-menu-item index="/ocr">OCR识别</el-menu-item>
          <el-menu-item index="/info">信息查询</el-menu-item>
          <div class="user-info">
            <span>{{ user ? user.username : '' }}</span>
            <el-button type="text" @click="handleLogout">退出</el-button>
          </div>
        </el-menu>
      </el-header>
      <el-main>
        <router-view></router-view>
      </el-main>
    </el-container>
  </div>
</template>

<script>
import axios from 'axios'
import { API_ENDPOINTS } from './config/api'

export default {
  name: 'App',
  data() {
    return {
      user: null
    }
  },
  created() {
    // 从localStorage获取用户信息
    const userStr = localStorage.getItem('user')
    if (userStr) {
      try {
        this.user = JSON.parse(userStr)
      } catch (e) {
        console.error('解析用户信息失败:', e)
        localStorage.removeItem('user')
      }
    }
    
    // 如果不是登录页，检查登录状态
    if (this.$route.path !== '/login') {
      this.checkLoginStatus()
    }
  },
  watch: {
    // 监听路由变化，非登录页时检查登录状态
    '$route.path'(newPath) {
      if (newPath !== '/login') {
        this.checkLoginStatus()
      }
    }
  },
  methods: {
    async checkLoginStatus() {
      if (!this.user) {
        this.$router.push('/login')
      }
    },
    async handleLogout() {
      try {
        await axios.post(API_ENDPOINTS.USER_LOGOUT)
        this.user = null
        // 清除本地存储的用户信息
        localStorage.removeItem('user')
        this.$message.success('退出成功')
        this.$router.push('/login')
      } catch (error) {
        console.error('退出失败:', error)
        this.$message.error('退出失败')
      }
    }
  }
}
</script>

<style>
html, body {
  margin: 0;
  padding: 0;
  height: 100%;
}

#app {
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.el-container {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.el-header {
  padding: 0;
  height: 60px !important;
}

.el-menu {
  display: flex;
  border-radius: 0;
}

.user-info {
  margin-left: auto;
  display: flex;
  align-items: center;
  color: #fff;
  padding: 0 20px;
}

.user-info span {
  margin-right: 10px;
}

.user-info .el-button {
  color: #fff;
}

.user-info .el-button:hover {
  color: #ffd04b;
}

.el-main {
  flex: 1;
  padding: 0;
  background-color: #f5f7fa;
  overflow-y: auto;
}

/* 移除 Element UI 的默认内边距 */
.el-menu--horizontal > .el-menu-item {
  height: 60px;
  line-height: 60px;
}
</style>
