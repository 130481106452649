<template>
  <div class="ocr-upload">
    <el-card class="upload-card">
      <div class="page-header">
        <div class="header-left">
          <h2 class="page-title">OCR图片识别</h2>
          <el-tag type="success" effect="dark" size="small">
            共 {{ tableData.length }} 条记录
          </el-tag>
        </div>
        <div class="header-right">
          <el-button type="success" icon="el-icon-upload" @click="dialogVisible = true">
            上传图片
          </el-button>
          <el-button type="primary" icon="el-icon-download" @click="exportExcel">
            查看
          </el-button>
          <el-button icon="el-icon-refresh" @click="loadData" :loading="loading">
            刷新
          </el-button>
        </div>
      </div>
      
      <div class="table-container">
        <el-table
          :data="tableData"
          style="width: 100%"
          border
          stripe
          height="600"
          v-loading="loading"
          element-loading-text="加载中..."
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(255, 255, 255, 0.8)"
        >
          <el-table-column
            prop="phoneNumber"
            label="手机号"
            fixed>
            <template slot-scope="scope">
              <div class="phone-number">
                <i class="el-icon-mobile-phone"></i>
                {{ scope.row.phoneNumber }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="region"
            label="地区">
            <template slot-scope="scope">
              <el-tag size="medium" effect="plain">{{ scope.row.region }}</el-tag>
            </template>
          </el-table-column>
          <el-table-column
            prop="timestamp"
            label="识别时间">
            <template slot-scope="scope">
              <div class="time-column">
                <i class="el-icon-time"></i>
                {{ scope.row.createTime }}
              </div>
            </template>
          </el-table-column>
        </el-table>

        <div v-if="!tableData.length && !loading" class="empty-state">
          <i class="el-icon-picture-outline"></i>
          <p>暂无识别记录，点击上方按钮上传图片</p>
        </div>
      </div>

      <el-dialog
        title="上传图片"
        :visible.sync="dialogVisible"
        width="520px"
        :before-close="handleClose"
        custom-class="upload-dialog"
        center>
        <div class="upload-area">
          <el-upload
            class="upload-component"
            action="#"
            :show-file-list="false"
            :http-request="handleUpload"
            :before-upload="beforeUpload"
            :disabled="uploading"
            drag>
            <div class="upload-content">
              <div class="upload-icon-wrapper" :class="{ 'is-uploading': uploading }">
                <i :class="['upload-icon', uploading ? 'el-icon-loading' : 'el-icon-upload']"></i>
              </div>
              <div class="upload-text">
                <template v-if="!uploading">
                  <h3>点击或拖拽图片上传</h3>
                  <p>支持 JPG/PNG 格式</p>
                </template>
                <template v-else>
                  <h3>正在识别中...</h3>
                  <p>请稍候</p>
                </template>
              </div>
            </div>
          </el-upload>
        </div>
      </el-dialog>
      
      <el-dialog
        title="数据预览"
        :visible.sync="previewVisible"
        width="95%"
        :fullscreen="true"
        :before-close="handleClosePreview"
        @closed="handleDialogClosed"
        custom-class="preview-dialog"
      >
        <div id="x-spreadsheet-demo" style="width: 100%; height: calc(100vh - 120px); overflow: auto;"></div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="previewVisible = false">关闭</el-button>
          <el-button type="primary" @click="downloadExcel">导出Excel</el-button>
        </span>
      </el-dialog>
      
    </el-card>
  </div>
</template>

<script>
import { ocrApi } from '@/api/ocr'
import { API_ENDPOINTS } from '@/api/config'
import Spreadsheet from 'x-data-spreadsheet'
import 'x-data-spreadsheet/dist/xspreadsheet.css'

export default {
  name: 'OcrUpload',
  data() {
    return {
      tableData: [],
      loading: false,
      dialogVisible: false,
      uploading: false,
      previewVisible: false,
      spreadsheet: null
    }
  },
  created() {
    this.loadData()
  },
  methods: {
    async loadData() {
      try {
        this.loading = true
        const res = await ocrApi.getList()
        if (res.code === 200) {
          this.tableData = res.data || []
        }
      } catch (error) {
        console.error('加载数据失败:', error)
        this.$message.error('加载数据失败：' + (error.message || '未知错误'))
      } finally {
        this.loading = false
      }
    },
    handleClose(done) {
      done()
    },
    beforeUpload(file) {
      const isImage = file.type === 'image/jpeg' || file.type === 'image/png'
      const isLt10M = file.size / 1024 / 1024 < 10

      if (!isImage) {
        this.$message.error('上传图片只能是 JPG/PNG 格式!')
        return false
      }
      if (!isLt10M) {
        this.$message.error('上传图片大小不能超过 10MB!')
        return false
      }
      return true
    },
    async handleUpload(params) {
      try {
        this.uploading = true
        const res = await ocrApi.upload(params.file)
        if (res.code === 200) {
          this.$message.success('上传成功')
          this.loadData()
        }
      } catch (error) {
        console.error('上传失败:', error)
        this.$message.error('上传失败：' + (error.message || '未知错误'))
      } finally {
        this.uploading = false
      }
    },
    handleClosePreview(done) {
      const container = document.getElementById('x-spreadsheet-demo')
      if (container) {
        container.innerHTML = ''
      }
      this.spreadsheet = null
      done()
    },
    handleDialogClosed() {
      const container = document.getElementById('x-spreadsheet-demo')
      if (container) {
        container.innerHTML = ''
      }
      this.spreadsheet = null
    },
    
    async exportExcel() {
      try {
        const userStr = localStorage.getItem('user')
        if (!userStr) {
          this.$message.error('请先登录')
          return
        }
        const user = JSON.parse(userStr)
        
        // 显示加载中
        const loading = this.$loading({
          lock: true,
          text: '正在加载数据...',
          spinner: 'el-icon-loading',
          background: 'rgba(255, 255, 255, 0.7)'
        })
        
        // 获取数据
        const res = await ocrApi.getList()
        console.log('API response:', res)
        
        if (res.code === 200 && res.data && Array.isArray(res.data)) {
          console.log('Data is valid, length:', res.data.length)
          
          this.previewVisible = true
          
          await this.$nextTick()
          
          const container = document.getElementById('x-spreadsheet-demo')
          if (!container) {
            console.error('Container not found!')
            return
          }
          
          // 清理旧的实例
          container.innerHTML = ''
          this.spreadsheet = null
          
          // 初始化电子表格
          try {
            // 创建实例
            this.spreadsheet = new Spreadsheet('#x-spreadsheet-demo')
            
            // 准备数据
            const data = {
              name: 'Sheet1',
              freeze: 'A1',
              styles: [
                {
                  bgcolor: '#f4f5f8',
                  textwrap: true,
                  color: '#900b09',
                  border: {
                    top: ['thin', '#0366d6'],
                    bottom: ['thin', '#0366d6'],
                    right: ['thin', '#0366d6'],
                    left: ['thin', '#0366d6'],
                  },
                },
              ],
              merges: [],
              rows: {
                0: {
                  height: 35,
                  cells: {
                    0: { text: '序号' },
                    1: { text: 'ID' },
                    2: { text: '用户名' },
                    3: { text: '手机号' },
                    4: { text: '地区' },
                    5: { text: '识别时间' },
                    6: { text: '创建时间' },
                    7: { text: '更新时间' },
                    8: { text: '删除标记' },
                    9: { text: '版本号' }
                  }
                }
              },
              cols: {
                len: 10
              }
            }
            
            // 添加数据行
            res.data.forEach((item, index) => {
              data.rows[index + 1] = {
                cells: {
                  0: { text: (index + 1).toString() },
                  1: { text: item.id.toString() },
                  2: { text: item.username || '' },
                  3: { text: item.phoneNumber || '' },
                  4: { text: item.region || '' },
                  5: { text: item.recognizeTime || '' },
                  6: { text: item.createTime || '' },
                  7: { text: item.updateTime || '' },
                  8: { text: item.deleted ? '是' : '否' },
                  9: { text: item.version.toString() }
                }
              }
            })
            
            console.log('Loading data:', data)
            
            // 加载数据
            this.spreadsheet.loadData(data)
            
          } catch (err) {
            console.error('Error initializing spreadsheet:', err)
            this.$message.error('初始化电子表格失败：' + err.message)
          }
          
        } else {
          console.error('Invalid data format:', res)
          this.$message.error('获取数据失败：数据格式不正确')
        }
        
        loading.close()
        
      } catch (error) {
        console.error('Error in exportExcel:', error)
        this.$message.error('加载数据失败：' + error.message)
        const container = document.getElementById('x-spreadsheet-demo')
        if (container) {
          container.innerHTML = ''
        }
        this.spreadsheet = null
      }
    },
    
    // 下载Excel文件
    downloadExcel() {
      const userStr = localStorage.getItem('user')
      if (!userStr) {
        this.$message.error('请先登录')
        return
      }
      const user = JSON.parse(userStr)
      window.open(`${process.env.VUE_APP_API_URL}/api/ocr/export?username=${user.username}`)
    }
  }
}
</script>

<style scoped>
.ocr-upload {
  padding: 20px;
  background-color: #f5f7fa;
  min-height: 100%;
  box-sizing: border-box;
}

.upload-card {
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.page-header {
  padding: 20px 24px;
  border-bottom: 1px solid #ebeef5;
  background-color: #fff;
  border-radius: 8px 8px 0 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-left {
  display: flex;
  align-items: center;
  gap: 12px;
}

.page-title {
  margin: 0;
  font-size: 20px;
  font-weight: 600;
  color: #303133;
}

.header-right {
  display: flex;
  gap: 12px;
}

.table-container {
  padding: 20px 24px;
  position: relative;
}

/* 表格样式优化 */
.el-table {
  border-radius: 8px;
  overflow: hidden;
}

.el-table::before {
  display: none;
}

.el-table td, .el-table th {
  border-right: none !important;
}

.el-table--border td, 
.el-table--border th {
  border-right: none;
}

.el-table--border::after {
  display: none;
}

.el-table__fixed-right::before, 
.el-table__fixed::before {
  display: none;
}

.el-table th {
  background-color: #f5f7fa !important;
  color: #303133;
  font-weight: 600;
  padding: 12px 0;
  border-bottom: 1px solid #ebeef5;
}

.el-table td {
  padding: 12px 0;
}

.el-table--striped .el-table__body tr.el-table__row--striped td {
  background: rgba(250, 250, 250, 0.6);
}

.el-table__body tr:hover > td {
  background-color: rgba(245, 247, 250, 0.6) !important;
}

/* 滚动条样式美化 */
.el-table__body-wrapper::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

.el-table__body-wrapper::-webkit-scrollbar-thumb {
  background-color: transparent;
  border-radius: 4px;
  transition: all 0.2s ease-in-out;
}

.el-table__body-wrapper:hover::-webkit-scrollbar-thumb {
  background-color: rgba(144, 147, 153, 0.3);
}

.el-table__body-wrapper::-webkit-scrollbar-thumb:hover {
  background-color: rgba(144, 147, 153, 0.5);
}

.el-table__body-wrapper::-webkit-scrollbar-track {
  background: transparent;
}

/* 空状态样式 */
.empty-state {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: #909399;
}

.empty-state i {
  font-size: 48px;
  margin-bottom: 16px;
}

.empty-state p {
  margin: 0;
  font-size: 14px;
}

.upload-dialog {
  border-radius: 12px;
  overflow: hidden;
}

.upload-dialog .el-dialog__header {
  background: #fff;
  padding: 20px 24px;
  margin: 0;
  text-align: center;
  position: relative;
}

.upload-dialog .el-dialog__title {
  font-size: 18px;
  font-weight: 600;
  color: #1a1a1a;
}

.upload-dialog .el-dialog__headerbtn {
  position: absolute;
  right: 16px;
  top: 16px;
  font-size: 20px;
}

.upload-dialog .el-dialog__body {
  padding: 0 24px 24px;
}

.upload-area {
  display: flex;
  justify-content: center;
  align-items: center;
}

.upload-component {
  width: 100%;
}

.upload-component .el-upload {
  width: 100%;
  display: block;
}

.upload-component .el-upload-dragger {
  width: 100%;
  height: 240px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px dashed #e4e7ed;
  border-radius: 12px;
  background: #fafafa;
  transition: all 0.3s ease;
  padding: 20px;
}

.upload-component .el-upload-dragger:hover {
  border-color: #409EFF;
  background: #f0f7ff;
}

.upload-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.upload-icon-wrapper {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background: #f0f7ff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;
  transition: all 0.3s ease;
}

.upload-icon-wrapper.is-uploading {
  background: #e6f2ff;
}

.upload-icon {
  font-size: 36px;
  color: #409EFF;
  transition: all 0.3s ease;
}

.upload-text h3 {
  font-size: 16px;
  font-weight: 500;
  color: #303133;
  margin: 0 0 8px;
}

.upload-text p {
  font-size: 14px;
  color: #909399;
  margin: 0;
}

.el-upload-dragger:hover .upload-icon-wrapper {
  transform: translateY(-2px);
  box-shadow: 0 6px 16px rgba(64, 158, 255, 0.1);
}

.phone-number, .time-column {
  display: flex;
  align-items: center;
  gap: 8px;
}

.phone-number i {
  color: #409EFF;
}

.time-column i {
  color: #909399;
}

.delete-btn {
  color: #F56C6C;
}

.delete-btn:hover {
  color: #f78989;
}

.preview-dialog {
  margin: 0 !important;
}

.preview-dialog :deep(.el-dialog__header) {
  padding: 15px 20px;
  border-bottom: 1px solid #e4e4e4;
}

.preview-dialog :deep(.el-dialog__body) {
  padding: 0;
  height: calc(100vh - 120px);
}

.preview-dialog :deep(.el-dialog__footer) {
  padding: 15px 20px;
  border-top: 1px solid #e4e4e4;
}

#x-spreadsheet-demo :deep(.x-spreadsheet) {
  height: 100% !important;
}
</style>
