// API配置
export const BASE_URL = ''  // 使用空字符串，让请求使用相对路径

import axios from 'axios'
import { Message } from 'element-ui'
import router from '@/router'

// 创建axios实例
const service = axios.create({
  baseURL: BASE_URL,
  timeout: 5000,
  withCredentials: true // 允许携带cookie
})

// 响应拦截器
service.interceptors.response.use(
  response => {
    const res = response.data
    if (res.code !== 200) {
      Message.error(res.msg || '请求失败')
      return Promise.reject(new Error(res.msg || '请求失败'))
    }
    return res
  },
  error => {
    if (error.response && error.response.status === 401) {
      Message.error('请先登录')
      router.push('/login')
    } else {
      Message.error(error.message)
    }
    return Promise.reject(error)
  }
)

// API端点
export const API_ENDPOINTS = {
  USER_LOGIN: '/user/login',
  USER_REGISTER: '/user/register',
  USER_LOGOUT: '/user/logout',
  OCR_UPLOAD: '/ocr/upload',
  OCR_LIST: '/ocr/list',
  OCR_EXPORT: '/ocr/export'
}

export default service
