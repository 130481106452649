<template>
  <div class="login-container">
    <el-card class="login-card">
      <div class="title">OCR图片识别系统</div>
      <div class="sub-title">{{ isLogin ? '登录' : '注册' }}</div>
      <el-form
        ref="form"
        :model="form"
        :rules="rules"
        label-width="0"
      >
        <el-form-item prop="username">
          <el-input
            v-model="form.username"
            prefix-icon="el-icon-user"
            placeholder="请输入用户名"
          />
        </el-form-item>
        
        <el-form-item prop="password">
          <el-input
            v-model="form.password"
            prefix-icon="el-icon-lock"
            type="password"
            placeholder="请输入密码"
            @keyup.enter="handleSubmit"
          />
        </el-form-item>

        <el-form-item>
          <el-button
            type="primary"
            :loading="loading"
            class="submit-btn"
            @click="handleSubmit"
          >
            {{ isLogin ? '登录' : '注册' }}
          </el-button>
        </el-form-item>

        <div class="switch-type">
          <span @click="isLogin = !isLogin">
            {{ isLogin ? '没有账号？去注册' : '已有账号？去登录' }}
          </span>
        </div>
      </el-form>
    </el-card>
  </div>
</template>

<script>
import { userApi } from '@/api'

export default {
  name: 'Login',
  data() {
    return {
      isLogin: true,
      loading: false,
      form: {
        username: '',
        password: ''
      },
      rules: {
        username: [
          { required: true, message: '请输入用户名', trigger: 'blur' },
          { min: 4, max: 20, message: '长度在 4 到 20 个字符', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          { min: 4, max: 20, message: '长度在 4 到 20 个字符', trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    async handleSubmit() {
      try {
        await this.$refs.form.validate()
        const data = {
          username: this.form.username,
          password: this.form.password
        }
        if (this.isLogin) {
          // 登录
          const res = await userApi.login(data)
          if (res.code === 200) {
            // 保存用户信息到localStorage
            localStorage.setItem('user', JSON.stringify({
              username: this.form.username
            }))
            this.$message.success('登录成功')
            // 跳转到首页
            this.$router.push('/ocr')
          }
        } else {
          // 注册
          await userApi.register(data)
          this.$message.success('注册成功')
          // 注册成功后切换到登录
          this.isLogin = true
          this.form.password = ''
        }
      } catch (error) {
        console.error('登录失败:', error)
        this.$message.error('登录失败：' + (error.message || '未知错误'))
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped>
.login-container {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f5f7fa;
  padding: 20px;
  box-sizing: border-box;
}

.login-card {
  width: 100%;
  max-width: 400px;
  border-radius: 8px;
}

.title {
  font-size: 28px;
  font-weight: 600;
  color: #303133;
  text-align: center;
  margin-bottom: 10px;
}

.sub-title {
  font-size: 18px;
  color: #606266;
  text-align: center;
  margin-bottom: 30px;
}

.submit-btn {
  width: 100%;
}

.switch-type {
  text-align: center;
  margin-top: 16px;
  color: #409EFF;
  cursor: pointer;
}

.switch-type:hover {
  color: #66b1ff;
}

.el-input :deep(.el-input__inner) {
  height: 42px;
  line-height: 42px;
}

.el-input :deep(.el-input__prefix) {
  left: 10px;
}

.el-input :deep(.el-input__inner) {
  padding-left: 35px;
}

.el-button {
  height: 42px;
  font-size: 16px;
}

.el-card :deep(.el-card__body) {
  padding: 30px;
}
</style>
