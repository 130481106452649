import request from '@/utils/request'
import { API_ENDPOINTS } from './config'

export const userApi = {
  // 用户登录
  login(data) {
    return request({
      url: API_ENDPOINTS.USER.LOGIN,
      method: 'post',
      data
    })
  },

  // 用户注册
  register(data) {
    return request({
      url: API_ENDPOINTS.USER.REGISTER,
      method: 'post',
      data
    })
  },

  // 用户登出
  logout() {
    return request({
      url: API_ENDPOINTS.USER.LOGOUT,
      method: 'post'
    })
  }
} 