import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '@/views/Login.vue'
import OcrUpload from '@/views/OcrUpload.vue'
import Home from '@/views/Home.vue'
import InfoQuery from '@/views/InfoQuery.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/home',
    name: 'Home',
    component: Home,
    meta: { requiresAuth: true }
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/ocr',
    name: 'OcrUpload',
    component: OcrUpload,
    meta: { requiresAuth: true }
  },
  {
    path: '/info',
    name: 'InfoQuery',
    component: InfoQuery,
    meta: { requiresAuth: true }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// 路由守卫
router.beforeEach((to, from, next) => {
  const user = localStorage.getItem('user')
  
  if (to.meta.requiresAuth && !user) {
    next('/login')
  } else {
    next()
  }
})

export default router