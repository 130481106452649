import axios from 'axios'
import { Message } from 'element-ui'
import router from '@/router'

// 创建axios实例
const service = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  timeout: 15000,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json;charset=UTF-8'
  }
})

// 请求拦截器
service.interceptors.request.use(
  config => {
    // 如果是文件上传，不设置Content-Type，让浏览器自动设置
    if (config.data instanceof FormData) {
      delete config.headers['Content-Type']
    }
    
    // 打印请求URL，用于调试
    console.log('Request URL:', config.baseURL + config.url)
    
    const userStr = localStorage.getItem('user')
    if (userStr) {
      const user = JSON.parse(userStr)
      // 添加username参数
      if (config.method === 'get') {
        config.params = { ...config.params, username: user.username }
      } else if (config.data instanceof FormData) {
        config.data.append('username', user.username)
      } else if (typeof config.data === 'object') {
        config.data = { ...config.data, username: user.username }
      } else {
        config.data = { username: user.username }
      }
    } else if (!config.url.includes('/login') && !config.url.includes('/register')) {
      router.push('/login')
      return Promise.reject(new Error('请先登录'))
    }
    return config
  },
  error => {
    console.log('请求错误:', error)
    return Promise.reject(error)
  }
)

// 响应拦截器
service.interceptors.response.use(
  response => {
    if (response.config.responseType === 'blob') {
      return response
    }
    const res = response.data
    
    // 如果响应不是200，显示错误信息
    if (res.code !== 200) {
      Message({
        message: res.msg || '错误',
        type: 'error',
        duration: 5 * 1000
      })
      return Promise.reject(new Error(res.msg || '错误'))
    }
    return res
  },
  error => {
    console.log('响应错误:', error)
    if (error.response) {
      switch (error.response.status) {
        case 401:
          Message.error('请先登录')
          localStorage.removeItem('user')
          router.push('/login')
          break
        case 403:
          Message.error('没有权限访问')
          break
        case 404:
          Message.error('请求的资源不存在')
          break
        case 500:
          Message.error('服务器错误，请稍后重试')
          break
        default:
          Message({
            message: error.response.data?.msg || '请求失败',
            type: 'error',
            duration: 5 * 1000
          })
      }
    } else {
      Message({
        message: error.message || '网络错误',
        type: 'error',
        duration: 5 * 1000
      })
    }
    return Promise.reject(error)
  }
)

export default service