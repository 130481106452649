<template>
  <div class="ban-query">
    <div class="action-buttons">
      <button @click="goToHome" class="action-btn home-btn">
        <i class="icon">⌂</i>
        返回首页
      </button>
      <button @click="isEditing = !isEditing" class="action-btn edit-btn">
        <i class="icon">✎</i>
        {{ isEditing ? '完成' : '编辑' }}
      </button>
      <button @click="downloadImage" class="action-btn download-btn">
        <i class="icon">↓</i>
        下载图片
      </button>
      <button @click="exportExcel" class="action-btn export-btn">
        <i class="icon">📊</i>
        导出Excel
      </button>
    </div>
    <div class="nav-path">
      <router-link to="/" class="nav-link">首页</router-link>
      <span class="separator">/</span> 查询验证
    </div>
    <h2 class="query-title">高等教育信息验证 <span class="online-query">(在线查询)</span></h2>
    
    <div class="query-box">
      <input 
        type="text" 
        v-model="queryNumber" 
        placeholder="请输入身份证号码进行查询"
        class="query-input"
      >
      <button @click="handleQuery" class="query-btn">查询</button>
    </div>

    <div v-if="showResult" class="result-container" ref="resultContent">
      <div class="result-header">
        <span class="result-title">查询结果</span>
        <span class="result-time">查询时间：{{ currentTime }}</span>
      </div>
      <div class="table-container">
        <div class="table-watermark">终身禁考</div>
        <table class="result-table">
          <tr>
            <td class="label">您查询的身份证号码</td>
            <td class="value id-number">
              <span v-if="!isEditing">{{ result.idNumber }}</span>
              <input 
                v-else 
                v-model="result.idNumber" 
                type="text" 
                class="edit-input"
              >
            </td>
          </tr>
          <tr>
            <td class="label">性别</td>
            <td class="value">
              <span v-if="!isEditing">{{ result.gender }}</span>
              <select v-else v-model="result.gender" class="edit-input">
                <option value="男">男</option>
                <option value="女">女</option>
              </select>
            </td>
          </tr>
          <tr>
            <td class="label">姓名</td>
            <td class="value">
              <span v-if="!isEditing">{{ result.name }}</span>
              <input 
                v-else 
                v-model="result.name" 
                type="text" 
                class="edit-input"
              >
            </td>
          </tr>
          <tr>
            <td class="label">预查询状态</td>
            <td class="value status-cell">
              <div class="ban-status">
                <span v-if="!isEditing">{{ result.status }}</span>
                <input 
                  v-else 
                  v-model="result.status" 
                  type="text" 
                  class="edit-input status-input"
                >
              </div>
            </td>
          </tr>
        </table>
      </div>
      <div class="note">注：本人自愿放弃考取学历</div>
    </div>
    <div v-if="!showResult" class="empty-result">
      <i class="empty-icon">🔍</i>
      <p>请输入身份证号码进行查询</p>
    </div>
  </div>
</template>

<script>
import html2canvas from 'html2canvas'
import service, { API_ENDPOINTS, BASE_URL } from '@/config/api'

export default {
  name: 'BanQuery',
  data() {
    return {
      queryNumber: '',
      showResult: false,
      isEditing: false,
      result: {
        idNumber: '632124200511260130',
        gender: '男',
        name: '张鑫安',
        status: '终身禁考（弃考入档）'
      },
      currentTime: ''
    }
  },
  methods: {
    handleQuery() {
      if (!this.queryNumber.trim()) {
        alert('请输入身份证号码');
        return;
      }
      this.showResult = true;
      this.currentTime = new Date().toLocaleString('zh-CN', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false
      });
    },
    async downloadImage() {
      try {
        // 确保不在编辑状态
        this.isEditing = false;
        await this.$nextTick();
        
        const element = this.$refs.resultContent;
        const canvas = await html2canvas(element, {
          scale: 2, // 提高清晰度
          useCORS: true,
          logging: false,
          backgroundColor: '#ffffff'
        });
        
        // 创建下载链接
        const link = document.createElement('a');
        link.download = '查询结果.png';
        link.href = canvas.toDataURL('image/png');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (error) {
        console.error('图片生成失败:', error);
        alert('图片生成失败，请稍后重试');
      }
    },
    exportExcel() {
      // 从localStorage获取用户名
      const username = localStorage.getItem('username');
      if (!username) {
        this.$message.error('请先登录');
        this.$router.push('/login');
        return;
      }

      // 创建一个隐藏的 iframe 来下载文件
      const iframe = document.createElement('iframe');
      iframe.style.display = 'none';
      iframe.src = `${BASE_URL}${API_ENDPOINTS.OCR_EXPORT}?username=${encodeURIComponent(username)}`;
      document.body.appendChild(iframe);

      // 设置一个定时器来移除 iframe
      setTimeout(() => {
        document.body.removeChild(iframe);
      }, 5000);
    },
    goToHome() {
      this.$router.push('/')
    }
  }
}
</script>

<style scoped>
.ban-query {
  width: 800px;
  margin: 20px auto;
  text-align: left;
  background-color: #f5f5f5;
  padding: 20px;
  box-shadow: 0 2px 12px 0 rgba(0,0,0,0.1);
}

.nav-path {
  color: #666;
  font-size: 14px;
  margin-bottom: 20px;
  padding-left: 5px;
}

.nav-link {
  color: #333;
  text-decoration: none;
  font-weight: bold;
}

.nav-link:hover {
  color: #000;
}

.separator {
  margin: 0 8px;
  color: #999;
}

.query-title {
  font-size: 24px;
  color: #333;
  margin-bottom: 30px;
  font-weight: normal;
  border-bottom: 2px solid #eee;
  padding-bottom: 15px;
}

.online-query {
  color: #ff0000;
  font-size: 20px;
}

.query-box {
  display: flex;
  gap: 10px;
  margin-bottom: 30px;
  background: #fff;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.05);
}

.query-input {
  flex: 1;
  padding: 8px 15px;
  border: 1px solid #ddd;
  border-radius: 0;
  font-size: 16px;
  height: 24px;
  transition: all 0.3s;
}

.query-input:focus {
  border-color: #409eff;
  box-shadow: 0 0 5px rgba(64,158,255,0.2);
}

.query-btn {
  padding: 8px 25px;
  background-color: #67c23a;
  color: white;
  border: none;
  border-radius: 0;
  cursor: pointer;
  font-size: 16px;
  height: 42px;
}

.query-btn:hover {
  background-color: #45a049;
}

.result-container {
  border: 1px solid #ddd;
  padding: 20px;
  background: #fff;
  box-shadow: 0 2px 4px rgba(0,0,0,0.05);
}

.result-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  padding-bottom: 10px;
  border-bottom: 1px solid #eee;
}

.result-time {
  color: #999;
  font-size: 14px;
}

.result-title {
  text-align: center;
  margin-bottom: 20px;
  font-size: 18px;
  color: #333;
}

.result-table {
  width: 100%;
  border-collapse: collapse;
  box-shadow: 0 1px 3px rgba(0,0,0,0.05);
  position: relative;
  z-index: 1;
  background: transparent;
  border: 1px solid #e0e0e0;
}

.result-table td {
  padding: 12px;
  border: 1px solid #e0e0e0;
  height: 24px;
  transition: background-color 0.3s;
  position: relative;
  z-index: 1;
  background: rgba(255, 255, 255, 0.85);
}

.result-table tr:first-child td {
  background: rgba(255, 255, 255, 0.85);
}

.result-table tr:hover {
  background-color: #f5f7fa;
}

.label {
  width: 200px;
  background-color: #f9f9f9 !important;
  font-weight: normal;
  color: #333;
  position: relative;
  z-index: 1;
}

.value {
  color: #666;
  position: relative;
  min-height: 24px;
}

.id-number {
  color: #67c23a;
}

.status-cell {
  position: relative;
}

.ban-status {
  color: #ff0000;
  font-weight: bold;
  position: relative;
  display: inline-block;
}

.note {
  color: #ff0000;
  font-size: 14px;
  text-align: right;
  margin-top: 10px;
}

.action-buttons {
  text-align: right;
  margin-bottom: 10px;
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.action-btn {
  padding: 6px 16px;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 5px;
  transition: all 0.3s;
}

.icon {
  font-style: normal;
  font-size: 16px;
}

.empty-result {
  text-align: center;
  padding: 40px 0;
  color: #909399;
}

.empty-icon {
  font-size: 48px;
  margin-bottom: 20px;
  display: block;
}

.edit-btn {
  padding: 6px 16px;
  background-color: #409eff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
}

.edit-btn:hover {
  background-color: #66b1ff;
}

.edit-input {
  width: 100%;
  padding: 4px 8px;
  border: 1px solid #ddd;
  border-radius: 2px;
  font-size: 14px;
  box-sizing: border-box;
}

.edit-input:focus {
  outline: none;
  border-color: #409eff;
}

.status-input {
  color: #ff0000;
  font-weight: bold;
}

.download-btn {
  padding: 6px 16px;
  background-color: #67c23a;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
}

.download-btn:hover {
  background-color: #529b2e;
}

.export-btn {
  background-color: #67c23a;
  color: white;
}

.export-btn:hover {
  background-color: #85ce61;
}

.table-container {
  position: relative;
  margin-bottom: 20px;
  overflow: hidden;
}

.table-watermark {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(-15deg);
  color: rgb(255, 0, 0);
  font-size: 60px;
  opacity: 1;
  font-weight: bold;
  pointer-events: none;
  white-space: nowrap;
  user-select: none;
  letter-spacing: 2px;
  z-index: 0;
  font-family: "KaiTi", "楷体", serif;
  text-shadow: 1px 1px 2px rgba(255, 0, 0, 0.3);
}

.home-btn {
  background-color: #909399;
}

.home-btn:hover {
  background-color: #606266;
}
</style>