// API路径配置
export const API_ENDPOINTS = {
  // 用户相关
  USER: {
    LOGIN: '/api/user/login',
    REGISTER: '/api/user/register',
    LOGOUT: '/api/user/logout'
  },
  // OCR相关
  OCR: {
    UPLOAD: '/api/ocr/upload',
    LIST: '/api/ocr/list',
    EXPORT: '/api/ocr/export'
  }
}

// 响应码
export const RESPONSE_CODE = {
  SUCCESS: 200,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  ERROR: 500
}