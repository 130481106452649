import request from '@/utils/request'
import { API_ENDPOINTS } from './config'

export const ocrApi = {
  // 上传文件
  upload(file) {
    const formData = new FormData()
    formData.append('file', file)
    
    return request({
      url: API_ENDPOINTS.OCR.UPLOAD,
      method: 'post',
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  },

  // 获取列表
  getList() {
    return request({
      url: API_ENDPOINTS.OCR.LIST,
      method: 'get'
    })
  },

  // 导出数据
  export() {
    return request({
      url: API_ENDPOINTS.OCR.EXPORT,
      method: 'get',
      responseType: 'blob',
      timeout: 60000
    })
  }
}