<template>
  <div class="registration-form">
    <div class="edit-toggle">
      <button @click="isEditing = !isEditing">{{ isEditing ? '完成编辑' : '编辑信息' }}</button>
      <button @click="downloadPDF" class="download-btn">下载PDF</button>
      <button @click="downloadImage" class="download-btn download-img-btn">下载图片</button>
      <button @click="goToQuery" class="action-btn query-btn">
        <i class="icon">🔍</i>
        查询验证
      </button>
    </div>
    <div class="form-content" ref="formContent">
      <h2 class="form-title">高等教育新生入学报名表</h2>
      <div class="form-container">
        <table class="reg-table">
          <tr>
            <td class="label">姓名:</td>
            <td>
              <span v-if="!isEditing">{{ formData.name }}</span>
              <input v-else v-model="formData.name" type="text">
            </td>
            <td class="label">性别:</td>
            <td>
              <span v-if="!isEditing">{{ formData.gender }}</span>
              <select v-else v-model="formData.gender">
                <option value="男">男</option>
                <option value="女">女</option>
              </select>
            </td>
            <td rowspan="5" class="photo-cell">
              <div class="photo-container">
                <img v-if="photoUrl" :src="photoUrl" alt="照片" class="photo">
                <div v-else class="photo-placeholder">
                  请上传照片
                  <input type="file" @change="handlePhotoUpload" accept="image/*">
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td class="label">身份证号:</td>
            <td colspan="3">
              <span v-if="!isEditing">{{ formData.idNumber }}</span>
              <input v-else v-model="formData.idNumber" type="text">
            </td>
          </tr>
          <tr>
            <td class="label">手机号:</td>
            <td colspan="3">
              <span v-if="!isEditing">{{ formData.phone }}</span>
              <input v-else v-model="formData.phone" type="text">
            </td>
          </tr>
          <tr>
            <td class="label">户籍所在地:</td>
            <td colspan="3">
              <span v-if="!isEditing" class="highlight-text">{{ formData.address }}</span>
              <input v-else v-model="formData.address" type="text">
            </td>
          </tr>
          <tr>
            <td class="label">报考专业:</td>
            <td colspan="3">
              <span v-if="!isEditing" class="highlight-text">{{ formData.major }}</span>
              <input v-else v-model="formData.major" type="text">
            </td>
          </tr>
          <tr>
            <td class="label">报考院校:</td>
            <td>
              <span v-if="!isEditing" class="highlight-text">{{ formData.school }}</span>
              <input v-else v-model="formData.school" type="text">
            </td>
            <td class="label">报考层次:</td>
            <td colspan="2">
              <span v-if="!isEditing">{{ formData.level }}</span>
              <select v-else v-model="formData.level">
                <option value="专科">专科</option>
                <option value="本科">本科</option>
                <option value="专本套读">专本套读</option>
                <option value="专本连读">专本连读</option>
                <option value="中专">中专</option>
              </select>
            </td>
          </tr>
          <tr>
            <td class="label">报考地点:</td>
            <td>
              <span v-if="!isEditing">{{ formData.examLocation }}</span>
              <input v-else v-model="formData.examLocation" type="text">
            </td>
            <td class="label">考试地点:</td>
            <td colspan="2">
              <span v-if="!isEditing">{{ formData.testLocation }}</span>
              <input v-else v-model="formData.testLocation" type="text">
            </td>
          </tr>
          <tr>
            <td class="label">授课方式:</td>
            <td>
              <span v-if="!isEditing">{{ formData.teachingMethod }}</span>
              <input v-else v-model="formData.teachingMethod" type="text">
            </td>
            <td class="label">授课时间:</td>
            <td colspan="2">
              <span v-if="!isEditing">{{ formData.teachingTime }}</span>
              <input v-else v-model="formData.teachingTime" type="text">
            </td>
          </tr>
        </table>

        <div class="footer">
          <div class="notice">
            <p>报考须知：</p>
            <p>1. 系统初审通过,办理入学后学院通知录取行政备案本人线上完成</p>
            <p>2. 本人承诺，以上信息正确属实，符合报考条件，自觉遵守报考流程，严格遵守考试纪律</p>
          </div>
          <div class="qr-code">
            <div class="qr-container">
              <img v-if="qrCodeUrl" :src="qrCodeUrl" alt="二维码" class="qr-image">
              <div v-else class="qr-placeholder">
                请上传二维码
                <input type="file" @change="handleQrCodeUpload" accept="image/*">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'

export default {
  name: 'RegistrationForm',
  data() {
    return {
      isEditing: false,
      photoUrl: '',
      qrCodeUrl: '',
      formData: {
        name: '',
        gender: '',
        idNumber: '',
        phone: '',
        address: '',
        major: '',
        school: '',
        level: '',
        examLocation: '',
        testLocation: '',
        teachingMethod: '',
        teachingTime: ''
      }
    }
  },
  methods: {
    handlePhotoUpload(event) {
      const file = event.target.files[0]
      if (file) {
        this.photoUrl = URL.createObjectURL(file)
      }
    },
    handleQrCodeUpload(event) {
      const file = event.target.files[0]
      if (file) {
        this.qrCodeUrl = URL.createObjectURL(file)
      }
    },
    async downloadPDF() {
      try {
        this.isEditing = false;
        
        await this.$nextTick();
        
        const element = this.$refs.formContent;
        const canvas = await html2canvas(element, {
          scale: 2,
          useCORS: true,
          logging: false,
          backgroundColor: '#ffffff'
        });
        
        const imgData = canvas.toDataURL('image/jpeg', 1.0);
        const pdf = new jsPDF({
          orientation: 'portrait',
          unit: 'mm',
          format: 'a4'
        });
        
        const imgProps = pdf.getImageProperties(imgData);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
        
        pdf.addImage(imgData, 'JPEG', 0, 0, pdfWidth, pdfHeight);
        pdf.save('入学报名表.pdf');
      } catch (error) {
        console.error('PDF生成失败:', error);
        alert('PDF生成失败，请稍后重试');
      }
    },
    async downloadImage() {
      try {
        this.isEditing = false;
        await this.$nextTick();
        
        const element = this.$refs.formContent;
        const canvas = await html2canvas(element, {
          scale: 2,
          useCORS: true,
          logging: false,
          backgroundColor: '#ffffff'
        });
        
        const link = document.createElement('a');
        link.download = '入学报名表.png';
        link.href = canvas.toDataURL('image/png');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (error) {
        console.error('图片生成失败:', error);
        alert('图片生成失败，请稍后重试');
      }
    },
    goToQuery() {
      this.$router.push('/query')
    }
  }
}
</script>

<style scoped>
.registration-form {
  width: 800px;
  margin: 20px auto;
  font-family: "Microsoft YaHei", "SimHei", serif;
  background: #fff;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
  padding: 20px;
}

.form-title {
  text-align: center;
  margin-bottom: 20px;
  font-size: 26px;
  font-weight: bold;
  letter-spacing: 2px;
  color: #000;
  padding: 15px 0;
  border-bottom: 2px solid #1a237e;
}

.form-container {
  border: 2px solid #1a237e;
  padding: 20px;
  background: #fff;
}

.reg-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
  border: 1px solid #1a237e;
}

.reg-table td {
  border: 1px solid #1a237e;
  padding: 8px;
  height: 40px;
  position: relative;
  font-weight: bold;
  font-size: 15px;
}

.label {
  width: 100px;
  font-weight: bold;
  text-align: right;
  background-color: #e8eaf6;
  color: #1a237e;
}

.photo-cell {
  width: 120px;
  text-align: center;
}

.photo-container {
  width: 110px;
  height: 150px;
  margin: 0 auto;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
}

.photo {
  max-width: 100%;
  max-height: 100%;
  width: 100%;
  height: 100%;
  object-fit: fill;
}

.photo-placeholder {
  text-align: center;
  font-size: 12px;
  color: #666;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: 2px dashed #1a237e;
  background-color: #f5f5f5;
}

.photo-placeholder input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

.qr-code {
  flex-shrink: 0;
}

.qr-container {
  display: inline-block;
  width: 100px;
  height: 100px;
  border: none;
  position: relative;
}

.qr-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.qr-placeholder {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 12px;
  color: #666;
  border: 2px dashed #1a237e;
  background-color: #f5f5f5;
}

.qr-placeholder input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

.notice {
  flex-grow: 1;
  margin-right: 20px;
  font-size: 14px;
  line-height: 1.8;
  text-align: left;
  font-weight: 500;
  padding: 15px;
  background-color: #f5f5f5;
  border: 1px solid #1a237e;
  border-radius: 2px;
}

.notice p {
  font-weight: bold;
  margin: 5px 0;
  color: #1a237e;
}

.edit-toggle {
  text-align: right;
  margin-bottom: 10px;
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.edit-toggle button {
  padding: 8px 16px;
  background-color: #1a237e;
  color: white;
  border: none;
  border-radius: 2px;
  cursor: pointer;
  font-weight: bold;
  transition: all 0.3s ease;
}

.edit-toggle button:hover {
  background-color: #283593;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.download-btn {
  padding: 8px 16px;
  background-color: #1565c0;
  color: white;
  border: none;
  border-radius: 2px;
  cursor: pointer;
  font-weight: bold;
  transition: all 0.3s ease;
}

.download-img-btn {
  background-color: #2e7d32;
}

.download-img-btn:hover {
  background-color: #1b5e20;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.download-btn:hover {
  background-color: #0d47a1;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

input[type="text"],
select {
  width: 100%;
  padding: 4px 8px;
  border: 1px solid #1a237e;
  border-radius: 2px;
  box-sizing: border-box;
  font-weight: bold;
  height: 30px;
  font-size: 14px;
}

input[type="text"]:focus,
select:focus {
  outline: none;
  border-color: #1a237e;
  box-shadow: 0 0 3px rgba(26, 35, 126, 0.3);
}

.reg-table td span {
  display: block;
  min-height: 20px;
}

.footer {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 20px;
}

.highlight-text {
  color: #d32f2f !important;
  font-weight: bold;
}

@media print {
  .registration-form {
    box-shadow: none;
    padding: 0;
  }
    
  .edit-toggle {
    display: none;
  }
  
  .form-container {
    border: 2px solid #000;
  }
  
  .reg-table td {
    border: 1px solid #000;
  }
}

.query-btn {
  background-color: #409eff;
  margin-right: auto;
}

.query-btn:hover {
  background-color: #66b1ff;
}
</style> 
  }
}
</style> 